import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const steps = [
  "Connect Your Wallet",
  "Fund Your Wallet with Cryptocurrency",
  "Purchase $BELU",
];

const howtobuy = [
  {
    title: "Connect Your Wallet",
    text: "Use the widget to connect your MetaMask or any other supported wallet to the website. You can easily purchase $BELU tokens using  ETH.",
  },
  {
    title: "Fund Your Wallet with Cryptocurrency",
    text: "Add your preferred cryptocurrency to your wallet. Once funded, you can proceed to purchase using the coins mentioned in Step 1.",
  },
  {
    title: "Purchase $BELU",
    text: "Enter the amount of $BELU you wish to purchase.",
  },
];
// Custom styles for the Stepper connector
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fff",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

// Custom StepIcon Component for styling the active, completed, and default icons
const CustomStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        backgroundColor: completed ? "#4caf50" : active ? "#A5CCEA" : "#757575",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
      }}
    >
      {completed ? (
        <Check />
      ) : (
        <Typography variant="body2">{props.icon}</Typography>
      )}
    </Box>
  );
};

export default function HowToBuyStepper() {
  const matches = useMediaQuery("(min-width:900px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  //   const handleSkip = () => {
  //     if (!isStepOptional(activeStep)) {
  //       throw new Error("You can't skip a step that isn't optional.");
  //     }
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     setSkipped((prevSkipped) => {
  //       const newSkipped = new Set(prevSkipped.values());
  //       newSkipped.add(activeStep);
  //       return newSkipped;
  //     });
  //   };

  const handleReset = () => setActiveStep(0);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        connector={<CustomConnector />}
        orientation={matches ? "horizontal" : "vertical"}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                StepIconComponent={CustomStepIcon}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: "#fff", // Default label color
                    fontFamily: "CCMaladroit",
                  },
                  "& .MuiStepLabel-label.Mui-active": {
                    color: "#A5CCEA", // Active step label color
                  },
                  "& .MuiStepLabel-label.Mui-completed": {
                    color: "#4caf50", // Completed step label color
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Box
          sx={{
            // p: 3,
            borderRadius: "20px",

            boxSizing: "border-box",
            mx: "auto",

            width: { xs: "100%", sm: "100%" },
            background: "rgba(255, 255, 255, 0.1)", // Semi-transparent background to see the blur
            backdropFilter: "blur(10px)", // Apply the blur effect
            WebkitBackdropFilter: "blur(10px)", // For Safari support
            transition: "all 0.3s ease-in-out", // Smooth transition
            my: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
              gap: "20px 0px",
              p: { xs: 2, md: 5 },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "17px" },
                color: "#fff",
                textAlign: "center",
                px: 2,
                fontFamily: "CCMaladroit",
              }}
            >
              {howtobuy?.[activeStep]?.text}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              fontSize: "13px",
              background: "#fff",
              borderRadius: "15px",
              border: "1px solid transparent",
              fontFamily: "CCMaladroit",
              color: "#000",
              "&:hover": {
                border: "1px solid #fff",
                color: "#fff",
              },
            }}
          >
            Previous Step
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === 2 ? (
            <Button
              onClick={handleReset}
              sx={{
                fontSize: "13px",
                background: "#fff",
                borderRadius: "15px",
                border: "1px solid transparent",
                fontFamily: "CCMaladroit",
                color: "#000",
                "&:hover": {
                  border: "1px solid #fff",
                  color: "#fff",
                },
              }}
            >
              Again
            </Button>
          ) : (
            <Button
              sx={{
                fontSize: "13px",
                background: "#fff",
                borderRadius: "15px",
                border: "1px solid transparent",
                fontFamily: "CCMaladroit",
                color: "#000",
                "&:hover": {
                  border: "1px solid #fff",
                  color: "#fff",
                },
              }}
              onClick={handleNext}
              disabled={activeStep === steps.length}
            >
              Got it
            </Button>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
}
